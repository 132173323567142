import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Img from "gatsby-image"

const TextImage = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const text = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const textVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <div className="inner mt-32 md:mt-40">
      <motion.div
        className="flex flex-wrap -mx-8 lg:-mx-12"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={text}
      >
        <div className="w-full md:w-5/12 px-8 lg:px-12">
          <motion.p variants={textVariants} className="text-20 mb-12">
            Through this focus we aim to benefit our clients by providing:
          </motion.p>

          <motion.ul
            variants={textVariants}
            className="list-disc list-outside ml-8 lg:ml-12"
          >
            <li>
              Business case investment appraisals and feasibility studies on
              solid project deliverables.
            </li>
            <li>Cost predictability.</li>
            <li>
              Cost management to assist designers early in the design process to
              achieve design, specification and cost targets.
            </li>
            <li>
              Confidence to allow projects to develop smoothly within client
              dictated criteria.
            </li>
            <li>Projects delivered to time, to quality and to cost.</li>
            <li>
              Added value through value engineering, minimising waste,
              innovation, whole life cost management, risk management and
              effective procurement strategies.
            </li>
          </motion.ul>
        </div>
        <div className="w-full md:w-7/12 px-8 lg:px-12">
          <motion.div variants={textVariants}>
            <Img fluid={props.img} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  )
}

export default TextImage
