import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Img from "gatsby-image"

const InnerHero = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const boxVariants = {
    visible: {
      width: "50%",
      transition: {
        duration: 0.6,
        stiffness: 75,
      },
    },
    hidden: {
      width: "100%",
    },
  }

  const imageVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
        stiffness: 75,
      },
    },
    hidden: {
      opacity: 0,
      x: 120,
    },
  }

  const textVariants = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const textChildrenVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <div className="container flex items-center h-120 sm:h-160 md:h-240 xl:h-320 relative overflow-hidden">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={boxVariants}
        className="hero-rect w-1/2 h-120 sm:h-160 md:h-240 xl:h-320 bg-gray-50 absolute left-0 top-0 bottom-0"
      ></motion.div>
      <div className="w-4/6 absolute right-0 top-0 bottom-0 mr-32 flex items-center justify-center">
        <div className="w-full">
          <motion.div
            initial="hidden"
            animate={controls}
            variants={imageVariants}
          >
            <Img fluid={props.img} className="hero-img" />
          </motion.div>
        </div>
      </div>
      <motion.div
        initial="hidden"
        animate={controls}
        variants={textVariants}
        className="hero-content w-4/6 lg:ml-64 relative"
      >
        <motion.p
          variants={textChildrenVariants}
          className="text-blue-200 text-20 dash-right"
        >
          {props.subtitle}
        </motion.p>
        <motion.h1
          variants={textChildrenVariants}
          className="flex flex-col mb-12 md:mb-16 lg:mb-24 text-50 md:text-70 xl:text-150"
        >
          {props.title}{" "}
          <span className="-mt-6 text-gray-100">{props.titlegray}</span>
        </motion.h1>
      </motion.div>
    </div>
  )
}

export default InnerHero
