import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import InnerHero from "../components/heros/inner"
import LargeTextBlock from "../components/textBlocks/large"
import TextImage from "../components/textBlocks/textImage"
import LogoList from "../components/lists/logoList"
import LogoCarousel from "../components/lists/logoCarousel"

// import Icon from "../components/icon/index.js"

// import SEO from "../components/seo";

function AboutPage(props) {
  const logos = [props.data.rics, props.data.ssip, props.data.constructionline]

  return (
    <Layout>
      <InnerHero
        subtitle="About"
        title="About"
        titlegray="Us"
        img={props.data.imageOne.childImageSharp.fluid}
      />

      <LogoList logos={logos} />

      <LargeTextBlock
        // title="With over 40 years experience we have you covered"
        text='<p class="mb-12"><span class="text-blue-200">Hawthorne Consulting (NI) Ltd </span>is a Chartered Surveying Practice regulated by the Royal Institution of Chartered Surveyors, established in 2004, providing Quantity Surveying, Project Management, Expert Witness and other specialist services to the construction industry throughout the UK and Europe to both public and private sectors clients on projects ranging in value from £50k - £250Mn</p><p class="mb-12">The focus of the Practice is on:</p><ul class="list-disc list-outside ml-8 lg:ml-12"><li>Providing strategic advice to the construction and property investment industry.</li><li>Adding commercial value to the construction development process.</li><li>Managing risk as a technical deliverable for reducing cost.</li><li>Providing a quality service.</li><li>Bringing integrity to the process.</li></ul>'
        imgLarge={props.data.aboutPageOne.childImageSharp.fluid}
      />
      <TextImage img={props.data.aboutPageTwo.childImageSharp.fluid} />

      {/* <div class="SRSJobsContainer bg-gray-50  md:h-122">
        <div className="container px-4 py-12 text-left sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <div>
            <h2 className="text-3xl font-extrabold leading-none text-gray-900 sm:text-4xl">
              Looking to join the best team?
              <br />
              <span className="text-blue-200">Apply now on Nijobs</span>
            </h2>
            <div className="inline-flex items-center justify-center px-8 py-6 mt-10 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-200 rounded-md hover:text-blue-200 hover:bg-white focus:outline-none focus:shadow-outline lg:mt-16">
              <a
                href="https://www.nijobs.com/company-profile/8683"
                title="Hawthorne Consulting jobs on NIJobs"
                target="_new"
              >
                <span class="btnText">Start Application</span>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <LogoCarousel logos={props.data.logos} />
    </Layout>
  )
}

export default AboutPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "logos/carousel" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imageOne: file(relativePath: { eq: "about-hero.jpg" }) {
      ...fluidImage
    }
    aboutPageOne: file(relativePath: { eq: "about-one.jpg" }) {
      ...fluidImage
    }
    aboutPageTwo: file(relativePath: { eq: "about-page-two.jpg" }) {
      ...fluidImage
    }
    homeProject: file(relativePath: { eq: "about-page-two.jpg" }) {
      ...fluidImage
    }
    rics: file(relativePath: { eq: "logos/rics.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    constructionline: file(relativePath: { eq: "logos/constructionline.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ssip: file(relativePath: { eq: "logos/ssip.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
